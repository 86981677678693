import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OfficeMailService } from 'src/app/services/office-mail.service';
import { NgProgressRef, NgProgress } from '@ngx-progressbar/core';
import { AlertComponent } from 'ngx-bootstrap/alert/alert.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  selectedValue: string;
  submitted: boolean;
  progressRef: NgProgressRef;
  alerts: Array<any>;

  constructor(private officeMailService: OfficeMailService, private progress: NgProgress, private zone: NgZone) {
    this.selectedValue = "PHISH";
    this.submitted = true;
    this.progressRef = progress.ref('progress-bar');
    this.alerts = new Array<any>();
  }

  ngOnInit() {
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  onSubmit(form: NgForm) {
    this.progressRef.start();
    this.submitted = false;

    if (!form.valid)
      return;

    let type = form.controls["radioGroup"].value;
    let comment = form.controls["comment"].value;

    //FETCH DETAILS FROM MAIL
    this.officeMailService.getMailItemDetailsAndReportEmail(type, comment)
      .subscribe((result: any) => {
        this.zone.run(() => {
          this.progressRef.complete();
          this.submitted = true;

          let alertType = '';
          if (result.message == 'Mail has already been reported.' || result.message.includes('already')) {
            alertType = 'warning';
          } else {
            alertType = 'success';
          }

          this.alerts=[{
            type: alertType,
            msg: result.message,
            timeout: 2000
          }];
        });
      },
        error => {
        this.zone.run(() => {
          this.progressRef.complete();
          this.submitted = true;

          this.alerts =[{
            type: 'danger',
            msg: error,
            timeout: 2000
          }];
        });
      });
  }
}
