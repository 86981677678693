import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { NgProgressModule } from '@ngx-progressbar/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { HttpClientModule } from '@angular/common/http'

import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { EncodingService } from './services/encoding.service';
import { OfficeMailService } from './services/office-mail.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgProgressModule.withConfig({
      spinnerPosition: "left"
    }),
    AlertModule.forRoot()
  ],
  providers: [EncodingService, OfficeMailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
