import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Mail } from '../models/mail';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // public log(getMessageUrl: string,accessToken) {
  //   return this.http.get( `https://cloud.metadesignsolutions.com/coupa-instance/log/${getMessageUrl}-${accessToken}`);
  // }
  //'https://localhost:8081/api/v1/office/review'
  //'https://139.59.82.8/api/v1/office/review'
  //'http://localhost:4040/api/v1/office/review'
  //'https://demoapi.emailremediator.com/api/v1/office/review'

  reportUrl: string;

  constructor(private http: HttpClient) {
    this.reportUrl ='https://er-ext-api.yesbank.in/api/v1/review/addreview'
  }

  public getMessageDetails(url: string, headers: any, params: HttpParams) {
    return this.http.get( url, { headers: headers, params: params });
  }

  public report(mailObj: Mail) {
    return this.http.post(this.reportUrl, mailObj);
    //, { headers: { "Content-Type": "application/json; charset=utf-8" } }
  }
}
