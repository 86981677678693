import { User } from './user';

export class Mail {
  constructor(
    public body: any,
    public id: any,
    public user: User,
    public attachments: Array<any>,
    public type: string,
    public comment: string,
    public headers: Array<any>,
    
  ) {}
}
