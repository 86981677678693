import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

declare let Office: any;

Office.initialize = function () { };

Office.onReady(function (info) {
  console.log(`Office.js is now ready in ${info.host} on ${info.platform}`);
  platformBrowserDynamic().bootstrapModule(AppModule)
    .then((sucess: any) => {
      console.log("Bootstrap sucess", sucess);
    })
    .catch(err => console.log("Bootstrap error", err));
});
