import { Injectable } from '@angular/core';
import { Mail } from '../models/mail';
import { User } from '../models/user';
import * as $ from 'jquery'
import { EncodingService } from './encoding.service';
import * as jwtDecode from 'jwt-decode';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { HeaderModel } from '../models/header-model';
import { Observable,combineLatest,forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

declare let Office: any;

@Injectable({
  providedIn: 'root'
})
export class OfficeMailService {
 
  hasData: boolean;

  constructor(private encodingService: EncodingService, private apiService: ApiService) { }

  getMailItemDetailsAndReportEmail(type: string, comment: string) {
    return new Observable(observer => {
      try {
        let mailItem = this.getMailItem();
        let userProfile = this.getUserProfile();
        let mailBody = mailItem.body;

        mailBody.getAsync(Office.CoercionType.Html, (result) => {
          if (result.status !== Office.AsyncResultStatus.Succeeded) {
            //throw error with appropriate message
          }
          else {
            let user = new User(userProfile.displayName, userProfile.emailAddress);
            let mailObj = new Mail(result, mailItem.itemId, user, mailItem.attachments, type, comment, new Array<any>());

            Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, (result) => {
              if (result.status === "succeeded") {
                let accessToken = result.value;
                let forkArrayToGetMailData: Array<Observable<any>> = [this.getMailItemDetails(accessToken, mailObj)];
                if (mailObj && mailObj.attachments && mailObj.attachments.length) {//with attachments
                  forkArrayToGetMailData.push(this.getAttachments(accessToken, mailObj));
                } 

                 forkJoin(forkArrayToGetMailData).subscribe(results => {
                   mailObj = results[0];
                   if (results[1]) {
                      mailObj.attachments = results[1];
                   }
                   
                    return this.apiService.report(mailObj)
                      .subscribe(result => {
                        observer.next(result);
                        observer.complete();
                      },
                        err => {
                          observer.error(JSON.stringify(err));//+test1+"@@@ 173");
                        });
                   
                  },
                   err => {
                      //observer.error(JSON.stringify(err)+"46");
                      observer.error(err);
                    });
                
                
              } else {
                observer.error("Unable to get callback token for Outlook Rest Services.");
              }
            });
          }
        });
      }
      catch (error) {
        observer.error(error);
        //observer.error("56");
      }
    });
  }

  getUserEmailFromJwtToken(accessToken: any): any {
    // parse the token
    let jwt: any = jwtDecode(accessToken);

    // 'appctx' contains smtp address
    if (jwt.appctx) {
      let json = JSON.parse(jwt.appctx);
      if(json)
        return json.smtp;
    }

    return null;
  }

  getMailItem() {
    return Office.context.mailbox.item;
  }

  getSettings() {
    return Office.context.roamingSettings;
  }

  getUserProfile() {
    return Office.context.mailbox.userProfile;
  }

  getItemRestId() {

    // Currently the only Outlook Mobile version that supports add-ins
    // is Outlook for iOS.
    if (Office.context.mailbox.diagnostics.hostName === "OutlookIOS"
      || Office.context.mailbox.diagnostics.hostName === "OutlookAndroid") {
      // itemId is already REST-formatted
      return Office.context.mailbox.item.itemId;
    } else {
      // Convert to an item ID for API v2.0
      return Office.context.mailbox.convertToRestId(
        Office.context.mailbox.item.itemId,
        Office.MailboxEnums.RestVersion.v2_0
      );
    }
  }

  getBaseUrl(url) {
    var parts = url.split("/");
    return parts[0] + "//" + parts[2];
  }

  getRestUrl(accessToken) {
    // Shim function to workaround
    // mailbox.restUrl == null case
    if (Office.context.mailbox.restUrl) {
      return this.getBaseUrl(Office.context.mailbox.restUrl);
    }

    // parse the token
    let jwt: any = jwtDecode(accessToken);

    // 'aud' parameter from token can be in a couple of
    // different formats.

    // Format 1: It's just the URL
    if (jwt.aud.match(/https:\/\/([^@]*)/)) {
      return jwt.aud;
    }

    // Format 2: GUID/hostname@GUID
    var match = jwt.aud.match(/\/([^@]*)@/);
    if (match && match[1]) {
      return "https://" + match[1];
    }

    // Couldn't find what we expected, default to
    // outlook.office.com
    return "https://outlook.office.com";
  }

  getMailItemDetails(accessToken, mailObj: Mail): Observable<Mail> {
    return new Observable(observer => {
      try {
        let itemId = this.getItemRestId();
        let restUrl = this.getRestUrl(accessToken);
        let getMessageUrl = restUrl + "/api/v2.0/me/messages/" + itemId;

        let params = new HttpParams();
        params = params.append("$select", "SingleValueExtendedProperties, ToRecipients,Body");
        params = params.append("$expand", "SingleValueExtendedProperties($filter=PropertyId eq 'String 0x007D')")

        // this.apiService.log(getMessageUrl,accessToken);
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken,
          "Accept": "application/json; odata.metadata=none"
        });
        
        return this.apiService.getMessageDetails(getMessageUrl, headers, params)
          .subscribe((result: any) => {
            if (result.SingleValueExtendedProperties !== undefined) {
              mailObj.headers = this.parseHeaders(result.SingleValueExtendedProperties[0].Value);
              if (result.ToRecipients && Office.context.mailbox.diagnostics.hostName === "OutlookAndroid") {
                let userEmail = this.getUserEmailFromJwtToken(accessToken);
                
                if (userEmail) {
                  let user = result.ToRecipients.find(x => x.EmailAddress["Address"] == userEmail);
                  mailObj.user = new User(user.EmailAddress["Name"], user.EmailAddress["Address"]);
                }
              }
              if (result.Body && result.Body.Content) {
                mailObj.body = {status: "succeeded",value:result.Body.Content}
                // mailObj = new Mail({status: "succeeded",value:result.Body.Content},mailObj.id,mailObj.user,mailObj.attachments,mailObj.type,mailObj.comment,mailObj.headers)
                // mailObj.body.value = result.Body.Content;
              }
              
              observer.next(mailObj);
              observer.complete()
              
            } else {//
              observer.error("Only Inbox reporting is supported");
            }
          },
            (error) => {
              observer.error(error);
            });
      }
      catch (e) {
        observer.error(e);
      }
    });
  }

  getDiagnostics() {
    var diagnostics = "";
    try {
      diagnostics += "Requirement set = " + this.getRequirementSet() + "\n";
      diagnostics += "hostname = " + Office.context.mailbox.diagnostics.hostName + "\n";
      diagnostics += "hostVersion = " + Office.context.mailbox.diagnostics.hostVersion + "\n";
      if (Office.context.mailbox.diagnostics.OWAView) {
        diagnostics += "OWAView = " + Office.context.mailbox.diagnostics.OWAView + "\n";
      }

      diagnostics += "itemType = " + Office.context.mailbox.item.itemType + "\n";

      diagnostics += "contentLanguage = " + Office.context.contentLanguage + "\n";
      diagnostics += "displayLanguage = " + Office.context.displayLanguage + "\n";
      diagnostics += "touchEnabled = " + Office.context.touchEnabled + "\n";
    } catch (e) {
      diagnostics = "Failed to get diagnostics";
    }

    return diagnostics;
  }

  getRequirementSet() {
    if (Office.context.requirements && Office.context.requirements.isSetSupported) {
      if (Office.context.requirements.isSetSupported("Mailbox", 1.6)) return "1.6";
      if (Office.context.requirements.isSetSupported("Mailbox", 1.5)) return "1.5";
      if (Office.context.requirements.isSetSupported("Mailbox", 1.4)) return "1.4";
      if (Office.context.requirements.isSetSupported("Mailbox", 1.3)) return "1.3";
      if (Office.context.requirements.isSetSupported("Mailbox", 1.2)) return "1.2";
      if (Office.context.requirements.isSetSupported("Mailbox", 1.1)) return "1.1";
      if (Office.context.requirements.isSetSupported("Mailbox", 1.0)) return "1.0";
    }

    if (Office.context.mailbox.addHandlerAsync) return "1.5?";
    if (Office.context.ui.displayDialogAsync) return "1.4?";
    if (Office.context.mailbox.item.saveAsync) return "1.3?";
    if (Office.context.mailbox.item.setSelectedDataAsync) return "1.2?";
    if (Office.context.mailbox.item.removeAttachmentAsync) return "1.1?";
    return "1.0?";
  }

  parseHeaders(headers) {

    // Initialize originalHeaders in case we have parsing problems
    // this.originalHeaders = headers;
    var lines = headers.match(/^.*([\n\r]+|$)/gm);

    var headerList = [];
    var iNextHeader = 0;
    for (var iLine = 0; iLine < lines.length; iLine++) {
      lines[iLine] = this.encodingService.clean2047Encoding(lines[iLine]);

      // Recognizing a header:
      // - First colon comes before first white space.
      // - We're not strictly honoring white space folding because initial white space.
      // - is commonly lost. Instead, we heuristically assume that space before colon must have been folded.
      // This expression will give us:
      // match[1] - everything before the first colon, assuming no spaces (header).
      // match[2] - everything after the first colon (value).
      var match = lines[iLine].match(/(^[\w-\.]*?): ?(.*)/);

      // There's one false positive we might get: if the time in a Received header has been
      // folded to the next line, the line might start with something like "16:20:05 -0400".
      // This matches our regular expression. The RFC does not preclude such a header, but I've
      // never seen one in practice, so we check for and exclude 'headers' that
      // consist only of 1 or 2 digits.
      if (match && match[1] && !match[1].match(/^\d{1,2}$/)) {
        headerList[iNextHeader] = new HeaderModel(match[1], match[2]);
        iNextHeader++;
      } else {
        if (iNextHeader > 0) {
          // Tack this line to the previous line
          headerList[iNextHeader - 1].value += " " + lines[iLine];
        } else {
          // If we didn't have a previous line, go ahead and use this line
          if (lines[iLine].match(/\S/g)) {
            headerList[iNextHeader] = new HeaderModel("", lines[iLine]);
            iNextHeader++;
          }
        }
      }
    }

    if (headerList.length > 0) {
      this.hasData = true;
    }
    return headerList;
  }

  getAttachments(accessToken, mailObj: Mail):Observable<Array<any>> {
    return new Observable(observer => {
      try {
        let itemId = this.getItemRestId();
        let restUrl = this.getRestUrl(accessToken);
        let getMessageUrl = restUrl + "/api/v2.0/me/messages/" + itemId +"/attachments";

        let params = new HttpParams();
        params = params.append("$select", "Id");

        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken,
          "Accept": "application/json; odata.metadata=none"
        });

        return this.apiService.getMessageDetails(getMessageUrl, headers, params)
          .subscribe((item: any) => {
            if (item.value && item.value.length) {
              
              let attchmentsValues = item.value.map(att => this.getAttachmentsConteByte(accessToken,att.Id));
              forkJoin(attchmentsValues).subscribe((result:Array<any>) => {
                observer.next(result);
                observer.complete();
                   
                },
                err => {
                  console.log(err)
                  observer.next(mailObj.attachments);
                  observer.complete();
                });
            
            } else {
              observer.next(mailObj.attachments);
              observer.complete();
            }
            
          },
            (error) => {
              console.log(error)
              observer.next(mailObj.attachments);
              observer.complete();
            });
      }
      catch (e) {
        observer.error(e);
        observer.complete();
      }
    });
  }

  getAttachmentsConteByte(accessToken, attachmentId: String) {
    return new Observable(observer => {
      try {
        let itemId = this.getItemRestId();
        let restUrl = this.getRestUrl(accessToken);
        let getMessageUrl = restUrl + "/api/v2.0/me/messages/" + itemId + "/attachments/" + attachmentId

        let params = new HttpParams();
    
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken,
          "Accept": "application/json; odata.metadata=none"
        });

        return this.apiService.getMessageDetails(getMessageUrl, headers, params)
          .subscribe((result: any) => {
            observer.next(result);
            observer.complete();
           
           
          },
            (error) => {
              console.log(error);
              observer.next({});
              observer.complete();
              //observer.error("@@@"+test1 +"@@@180");
            });
      }
      catch (e) {
        console.log(e);
        observer.next({});
        observer.complete();
        //observer.error(e+"184");
      }
    });
  }

}
